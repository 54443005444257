/* eslint-disable vue/no-unused-vars */
<template>

  <b-container fluid>
    <!--ventana confirmacion -->
    <b-alert v-model="modalConfirmacion" variant="success" show>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle"
             viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
          <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
        </svg>
        {{ mensajeConfirmacion }}
      </div>
    </b-alert>
    <!--cerrar ventana confirmacion -->


    <!-- VENTANA ESPERAR-->
    <b-alert v-model="modalEsperar" show variant="warning">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-history"
             viewBox="0 0 16 16">
          <path
              d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
          <path
              d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
        </svg>
        Favor de esperar un momento, procesando información...
      </div>
    </b-alert>
    <!-- CERRAR VENTANA ESPERAR-->
    <!-- modal agregar Fechador -->
    <b-modal v-model="modalAgregarFechador" persistent title="Agregar fechador" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  Capturar los datos que se solicitan, para agregar la sección del Fechador.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-0">
                    <b-col sm="2">
                      <label>Contenido</label>
                    </b-col>
                    <b-col sm="10">
                      <b-form-input
                          ref="contenido"
                          v-model="sesion.contenido"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="success"
              size="sm"
              class="float-right ml-1"
              @click="guardarFechador()"
          >
            Guardar
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="modalAgregarFechador=false">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE agregar Fechador --->





    <!-- modal agregar Ponente -->
    <b-modal v-model="modalAgregarPonente" persistent title="Agregar ponente" size="lg" centered>
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <div class="card card-block">
              <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                </div>
                <b-alert
                    class="p-3"
                    show
                    variant="primary"
                >
                  Capturar los datos que se solicitan, para agregar al ponente.
                </b-alert>
                <b-row style="display:flex; justify-content: flex-end" class="mx-2 my-2">
                </b-row>

              </div>

              <b-form>
                <b-container fluid>
                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Nombre</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="nombre"
                          v-model="sesion.nombre"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Apellidos</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="apellidos"
                          v-model="sesion.apellidos"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Biografía</label>
                    </b-col>
                    <b-col sm="10">
                      <b-form-textarea
                          ref="biografia"
                          v-model="sesion.biografia"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Email personal</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailPersonal"
                          v-model="sesion.email"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Email interno</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="emailInterno"
                          v-model="sesion.email_interno"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Facebook</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="facebook"
                          v-model="sesion.facebook"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>Instagram</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="instagram"
                          v-model="sesion.instagram"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Twitter</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="twitter"
                          v-model="sesion.twitter"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                    <b-col sm="2">
                      <label>LinkedIn</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-input
                          ref="linkedin"
                          v-model="sesion.linkedin"
                          type="text"
                      >
                      </b-form-input>
                    </b-col>
                  </b-row>

                  <b-row class="my-1">
                    <b-col sm="2">
                      <label>Certificaciones</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-textarea
                          ref="certificaciones"
                          v-model="sesion.certificaciones"
                          type="text"
                          rows="2"
                          max-rows="2"
                      >
                      </b-form-textarea>
                    </b-col>
                    <b-col sm="2">
                      <label>Ponente nivelA</label>
                    </b-col>
                    <b-col sm="4">
                      <b-form-select
                          ref="ponenteNivelA"
                          v-model="sesion.ponenteNivelA"
                          :options="optionsPonenteNivelA"
                          size="sm"
                      >

                      </b-form-select>
                    </b-col>
                  </b-row>
                  <!--


                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Máximo asistentes</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="maximo_permitido_asistentes"
                                            v-model="sesion.maximo_permitido_asistentes"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Hora inicio</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input v-model="sesion.horario_inicio_ponencia" type="time"></b-form-input>
                                      </b-col>

                                      <b-col sm="3">
                                        <label>Hora fin</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input v-model="sesion.horario_fin_ponencia" type="time"></b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Número ponencia</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="numero_ponencia"
                                            v-model="sesion.numero_ponencia"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                      <b-col sm="3">
                                        <label>Duración minutos</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="duracion_minutos_ponencia"
                                            v-model="sesion.duracion_minutos_ponencia"
                                            type="number"
                                        >
                                        </b-form-input>
                                      </b-col>
                                    </b-row>

                                    <b-row class="my-1">
                                      <b-col sm="3">
                                        <label>Nivel ponencia</label>
                                      </b-col>
                                      <b-col sm="3">
                                        <b-form-input
                                            ref="nivel_ponencia"
                                            v-model="sesion.nivel_ponencia"
                                            type="text"
                                        >
                                        </b-form-input>
                                      </b-col>
                                      <b-col sm="2">
                                        <label>Sección</label>
                                      </b-col>
                                      <b-col sm="4">
                                        <b-form-textarea
                                            ref="seccion"
                                            v-model="sesion.seccion"
                                            type="text"
                                            rows="1"
                                            max-rows="1"
                                        >
                                        </b-form-textarea>
                                      </b-col>
                                    </b-row>-->


                  <div class="col-sm-12 my-2">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-right mx-2 my-1"
                        @click="guardarPonente()"
                    >
                      Guardar
                    </b-button>
                  </div>
                </b-container>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideModalAgregarPonente()">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
    <!-- FIN MODAL DE agregar Ponente --->



    <!-- modal borrar ponente -->
    <b-modal
        v-model="modalDeleteSesion"
        persistent
        title=" Confirmación"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de eliminar de eliminar el registro del Ponente: <b>{{this.sesion.nombre}} {{this.sesion.apellidos}}</b> ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="danger"
              size="sm"
              class="float-right ml-2"
              @click="deletePonente()"
          >
            Eliminar
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click=" modalDeleteSesion= false"
          >
            Cancelar
          </b-button>
        </div>
      </template>

    </b-modal>
    <!-- FIN borrar ponente -->


    <!--DASHBOARD PRINCIPAL-->
    <b-row fluid>
      <b-col cols="12">

        <div class="card card-block">
          <div class="card-body p-2">
            <div class="d-flex justify-content-between align-items-center p-2">
              <h5 class="font-weight-bold">Agenda Creator</h5>
            </div>
            <div class="d-flex justify-content-end align-items-center p-2 my-2">
<!--              <b-button :disabled=disabledButton variant="outline-secondary" size="md"
                        @click="modalAgregarPonente=!modalAgregarPonente">
                Agregar ponencia
              </b-button>-->

              <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Fechador"
                        @click="modalAgregarFechador=true">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-date" viewBox="0 0 16 16">
                  <path d="M6.445 12.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
              </b-button>
              <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Banner"
                        @click="getPonentes()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-window" viewBox="0 0 16 16">
                  <path d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                  <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm13 2v2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zM2 14a1 1 0 0 1-1-1V6h14v7a1 1 0 0 1-1 1H2z"/>
                </svg>
              </b-button>

              <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Horario"
                        @click="getPonentes()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
              </svg>
              </b-button>

              <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Fila sesión"
                        @click="getPonentes()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-distribute-vertical" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1 1.5a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0-.5.5zm0 13a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 0-1h-13a.5.5 0 0 0-.5.5z"/>
                  <path d="M2 7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7z"/>
                </svg>
              </b-button>





            </div>
            <div class="d-flex justify-content-between row p-2">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Actualizar listado"
                          @click="getPonentes()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                       class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                    <path
                        d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                    <path fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                  </svg>
                </b-button>
              </div>
              <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                <div>
                  <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      placeholder="Buscar"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div v-for="item in resultSet" :key="item.id" fluid>
              <b-row v-if="item.tipo=='fechador'" >
                <b-col cols="12">
                  <div>
                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Subir nivel"
                              @click="subirNivel(item)" align="right" v-if="item.posicion>0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Bajar nivel"
                              @click="bajarNivel(item)" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Eliminar"
                              @click="getPonentes()" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </b-button>
                  </div>
                  <div class="card card-block">
                    <div class="card-body p-2">
                      <h3 v-bind:style="{'color':item.color_hexadecimal}">{{item.contenido}}</h3>
                    </div>
                  </div>

                </b-col>
              </b-row>

              <b-row v-if="item.tipo=='banner'">
                <b-col cols="12">
                  <div class="card card-block">
                    <div>
                      <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Subir nivel"
                                @click="getPonentes()" align="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                        </svg>
                      </b-button>

                      <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Bajar nivel"
                                @click="getPonentes()" align="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                        </svg>
                      </b-button>

                      <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Eliminar"
                                @click="getPonentes()" align="right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                        </svg>
                      </b-button>
                    </div>
                    <b-img
                        center
                        rounded
                        :src="item.contenido"
                        height="auto"
                        :width="item.width"
                        fluid
                    >
                    </b-img>
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="item.tipo=='horario'">
                <b-col cols="12">
                  <div>
                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Subir nivel"
                              @click="getPonentes()" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Bajar nivel"
                              @click="getPonentes()" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Eliminar"
                              @click="getPonentes()" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </b-button>
                  </div>
                  <div class="card card-block">
                    <div class="card-body p-2" v-bind:style="{'background-color':item.background_color}">
                      <h4 v-bind:style="{'color':item.color_hexadecimal}">{{item.contenido}}</h4>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="item.tipo=='fila-sesion'">
                <b-col  xs="12" sm="6" md="6" lg="6" xl="6" v-for="(ponencia, index) in item.sesiones" :key="index">
                  <div>
                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Alinear a la derecha"
                              @click="getPonentes()" v-if="ponencia.align==='1'">
                      <!-- flecha derecha-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Alinear a la izquierda"
                              @click="getPonentes()" v-if="ponencia.align==='2'">
                      <!-- flecha izquierda-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                      </svg>
                    </b-button>

                    <b-button :disabled=disabledButton size="sm" variant="outline-light" title="Eliminar"
                              @click="getPonentes()" align="right">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
                      </svg>
                    </b-button>
                  </div>
                  <div>
                    <b-card-group deck>
                      <b-card :img-src="ponencia.imagen" img-alt="Image" style="height: 41rem">
                        <b-card-text class="text-justify">
                          {{ ponencia.descripcion_ponencia }}
                        </b-card-text>
                        <template #footer>
                          <b-row>
                            <b-col cols="12" md="8" lg="12">Nivel:&nbsp;{{ ponencia.nivel_ponencia }}</b-col>
                            <b-col cols="12" md="8" lg="12">Salón:&nbsp;<b>{{ ponencia.nombre_salon }}</b></b-col>
                            <b-col cols="12" md="8" lg="12">Sección:&nbsp;{{ ponencia.seccion }}</b-col>
                            <b-col cols="12" md="8" lg="12">
                              <br>
                              <b-button :pressed="false" variant="primary" size="sm" title="Editar"
                                        @click="inscribirse(ponencia.id)" v-if="ponencia.inscrito===0 && ponencia.total_inscritos<ponencia.maximo_permitido_asistentes">
                                Inscribirse
                              </b-button>
                              <b-badge variant="success" v-if="ponencia.inscrito===1">Inscrito</b-badge>
                              <b-badge variant="warning" v-if="ponencia.inscrito===0 && ponencia.total_inscritos===ponencia.maximo_permitido_asistentes">Cupo lleno</b-badge>
                            </b-col>
                          </b-row>
                        </template>
                      </b-card>
                    </b-card-group>
                  </div>
                </b-col>
              </b-row>

            </div>
          </div>
        </div>
      </b-col>
    </b-row>


  </b-container>
</template>

<script>
import toast from "@/mixins/ToastMixin.js";
import PonenteService from '@/services/PonenteService';
import ComponentesService from '@/services/ComponentesService';
import moment from "moment"
import 'moment/locale/es';

export default {
  mixins: [toast],
  data() {
    return {
      modalAgregarFechador:false,
      colorStyles: {
        //backgroundColor: '',
        color: ''
      },
      modalConfirmacion: false,
      mensajeConfirmacion: null,
      disabledButton: false,
      modalVerPonente: false,
      modalEditarPonente: false,
      nombre_sesion_actual: null,
      sesion_id_actual: null,
      modalDeleteSesion: false,
      sesion: {
        id: 0
        , nombre: null
        ,contenido:null
      },
      dia_ponencia: [
        {value: 1, text: '1'},
        {value: 2, text: '2'}
      ],
      modalAgregarPonente: false,
      modalEsperar: false,
      modalValidarIngreso: false,
      fields: [
        {label: 'Nombre completo', key: 'nombrecompleto', align: 'left'}
        , {label: 'Biografía', key: 'biografia', align: 'left'}
        , {label: 'Email', key: 'email', align: 'left'}
        , {label: 'Certificaciones', key: 'certificaciones', align: 'left'}
        , {label: 'Acciones', key: 'acciones', align: 'center'}
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      resultSet: [],
      search: '',
      nombre_evento: null,
      numero_cuenta_pago: null,

      totalRows2: 1,
      currentPage2: 1,
      perPage2: 5,
      pageOptions2: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter2: null,
      filterOn2: [],
      resultSet2: [],
      search2: '',
      optionsPonenteNivelA: [
        {value: 'Si', text: 'Si'},
        {value: 'No', text: 'No'}
      ],
      totalRows3: 1,
      currentPage3: 1,
      perPage3: 5,
      pageOptions3: [5, 10, 15, {value: 100, text: "Show a lot"}],
      filter3: null,
      filterOn3: [],
      resultSet3: []
      , infoPago: {
        id: 0,
        url_comprobante_pago: null,
        comprobanteEstatusAprobado: 0,
        observacionesValidacion: null,
        cliente: {
          name: null,
          escuela_procedencia: null,
          email: null
        }
      }
    }
  },
  methods: {

    async subirNivel(item)
    {
        this.showWait()
        let formData = new FormData();
        formData.append("idComponente", item.id);
        formData.append("accion", "subir");
        //TODO:
        //formData.append("color_hexadecimal", "this.sesion.color_hexadecimal");
        this.modalAgregarFechador = false
        const response = await ComponentesService.subirNivel(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          this.showConfirmacion(message)
          await this.getComponentes()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
    },
    async bajarNivel(item)
    {
      this.showWait()
      let formData = new FormData();
      formData.append("idComponente", item.id);
      formData.append("accion", "bajar");
      //TODO:
      //formData.append("color_hexadecimal", "this.sesion.color_hexadecimal");
      this.modalAgregarFechador = false
      const response = await ComponentesService.bajarNivel(formData);
      let {
        success,
        message
      } = response.data;
      if (success) {
        this.hiddeWait()
        this.showConfirmacion(message)
        await this.getComponentes()
      } else {
        this.hiddeWait()
        this.showConfirmacion(message)
      }
    },
    async getComponentes(){
      //ComponentesService
      this.showWait()
      this.resultSet = []
      const response = await ComponentesService.getComponentes();
      this.hiddeWait()
      this.resultSet = response.data.componentes;
      console.log("componentes-->")
      console.log(this.resultSet)
      this.totalRows = response.data.cantidad;

    },
    hideModalAgregarPonente(){
      this.modalAgregarPonente=false
      this.hiddeWait()
    },
    async guardarFechador()
    {
      if (this.sesion.contenido == null) {
        this.toast('b-toaster-top-center', true, '¡Debe capturar el Contenido!', 'danger');
        return false;
      }
      else{
        this.showWait()
        let formData = new FormData();
        formData.append("contenido", this.sesion.contenido);
        formData.append("tipo", "fechador");
        //TODO:
        //formData.append("color_hexadecimal", "this.sesion.color_hexadecimal");
        this.modalAgregarFechador = false
        const response = await ComponentesService.guardarFechador(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          this.showConfirmacion(message)
          await this.getComponentes()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
        this.sesion.contenido=null
      }
    },
    async guardarPonente()
    {
      if (this.sesion.nombre == null) {
        this.toast('b-toaster-top-center', true, '¡Debe capturar el nombre del ponente!', 'danger');
        return false;
      } else if (this.sesion.apellidos == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar los apellidos del ponente!', 'danger');
        return false;
      } else if (this.sesion.biografia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la biografía del ponente!', 'danger');
        return false;
      } else if (this.sesion.email == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el email del ponente!', 'danger');
        return false;
      } else if (this.sesion.ponenteNivelA == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe si el ponente es de nivelA!', 'danger');
        return false;
      }
      else{
        this.showWait()
        let formData = new FormData();
        formData.append("nombre", this.sesion.nombre);
        formData.append("apellidos", this.sesion.apellidos);
        formData.append("biografia", this.sesion.biografia);
        formData.append("email", this.sesion.email);
        formData.append("facebook", this.sesion.facebook);
        formData.append("instagram", this.sesion.instagram);
        formData.append("twitter", this.sesion.twitter);
        formData.append("linkedin", this.sesion.linkedin);
        formData.append("certificaciones", this.sesion.certificaciones);
        formData.append("email_interno", this.sesion.email_interno);
        formData.append("ponenteNivelA", this.sesion.ponenteNivelA);
        //this.wait()
        this.modalAgregarPonente = false
        const response = await PonenteService.guardarPonencia(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          this.showConfirmacion(message)
          await this.getPonentes()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
        this.resetForm()
      }
    },
    async deletePonente() {
      this.showWait()
      let formData = new FormData();
      formData.append("id", this.sesion.id);
      this.modalDeleteSesion = false
      this.modalEsperar = true
      const response = await PonenteService.deletePonente(formData);
      let {
        success,
        message,
      } = response.data;
      if (success) {
        this.hiddeWait()
        this.showConfirmacion(message)
        this.resetForm()
        await this.getPonentes()
      }
      else {
        this.hiddeWait()
        this.showConfirmacion(message)
      }
      this.resetForm()
    },
    async updatePonente() {

      if (this.sesion.nombre == null) {
        this.toast('b-toaster-top-center', true, '¡Debe capturar el nombre del ponente!', 'danger');
        return false;
      } else if (this.sesion.apellidos == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar los apellidos del ponente!', 'danger');
        return false;
      } else if (this.sesion.biografia == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar la biografía del ponente!', 'danger');
        return false;
      } else if (this.sesion.email == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe capturar el email del ponente!', 'danger');
        return false;
      } else if (this.sesion.ponenteNivelA == null) {
        this.toast('b-toaster-bottom-center', true, '¡Debe si el ponente es de nivelA!', 'danger');
        return false;
      }
      else{
        this.showWait()
        let formData = new FormData();
        formData.append("id", this.sesion.id);
        formData.append("nombre", this.sesion.nombre);
        formData.append("apellidos", this.sesion.apellidos);
        formData.append("biografia", this.sesion.biografia);
        formData.append("email", this.sesion.email);
        formData.append("facebook", this.sesion.facebook);
        formData.append("instagram", this.sesion.instagram);
        formData.append("twitter", this.sesion.twitter);
        formData.append("linkedin", this.sesion.linkedin);
        formData.append("certificaciones", this.sesion.certificaciones);
        formData.append("email_interno", this.sesion.email_interno);
        formData.append("ponenteNivelA", this.sesion.ponenteNivelA);
        //this.wait()
        this.modalEditarPonente = false
        const response = await PonenteService.actualizarPonente(formData);
        let {
          success,
          message
        } = response.data;
        if (success) {
          this.hiddeWait()
          this.showConfirmacion(message)
          await this.getPonentes()
        } else {
          this.hiddeWait()
          this.showConfirmacion(message)
        }
        this.resetForm()
      }
    },
    showWait() {
      this.modalEsperar = true
      this.disabledBtn()
    },
    hiddeWait() {
      this.modalEsperar = false
      this.enabledBtn()
    },
    disabledBtn() {
      this.disabledButton = true
    },
    enabledBtn() {
      this.disabledButton = false
    },
    resetForm() {
      this.sesion.id = 0
      this.sesion.user_id = 0
      this.sesion.nombre = null
      this.sesion.apellidos = null
      this.sesion.biografia = null
      this.sesion.email = null
      this.sesion.facebook = null
      this.sesion.instagram = null
      this.sesion.twitter = null
      this.sesion.linkedin = null
      this.sesion.certificaciones = null
      this.sesion.estatusActivo = null
      this.sesion.ponenteNivelA = null
      this.sesion.email_interno = null
    },

    hidemodalValidarPago() {
      this.modalValidarIngreso = false
    },
    convertir_fecha(fechahora) {
      return moment(String(fechahora)).format('dddd DD/MM/YYYY hh:mm a')
    },
    async getPonentes() {
      this.showWait()
      this.resultSet = []
      const response = await PonenteService.getPonentes();
      this.hiddeWait()
      this.resultSet = response.data.ponentes;
      this.totalRows = response.data.cantidad;
    },
    showConfirmacion(message) {
      this.mensajeConfirmacion = message
      setTimeout(() => {
        this.modalConfirmacion = false
      }, 3000);
      this.modalConfirmacion = true

    },
    hiddeConfirmacion() {
      this.mensajeConfirmacion = null
      this.modalConfirmacion = false
    },
    showModalVer(sessionSelected){
      this.modalVerPonente=true
      this.sesion.id = sessionSelected.id
      this.sesion.nombre = sessionSelected.nombre
      this.sesion.apellidos = sessionSelected.apellidos
      this.sesion.biografia = sessionSelected.biografia
      this.sesion.email = sessionSelected.email
      this.sesion.facebook = sessionSelected.facebook
      this.sesion.instagram = sessionSelected.instagram
      this.sesion.twitter = sessionSelected.twitter
      this.sesion.linkedin = sessionSelected.linkedin
      this.sesion.certificaciones = sessionSelected.certificaciones
      this.sesion.estatusActivo = sessionSelected.estatusActivo
      this.sesion.ponenteNivelA = sessionSelected.ponenteNivelA
      this.sesion.email_interno = sessionSelected.email_interno
    },
    showModalUpdate(sessionSelected){
      this.modalEditarPonente=true
      this.sesion.id = sessionSelected.id
      this.sesion.nombre = sessionSelected.nombre
      this.sesion.apellidos = sessionSelected.apellidos
      this.sesion.biografia = sessionSelected.biografia
      this.sesion.email = sessionSelected.email
      this.sesion.facebook = sessionSelected.facebook
      this.sesion.instagram = sessionSelected.instagram
      this.sesion.twitter = sessionSelected.twitter
      this.sesion.linkedin = sessionSelected.linkedin
      this.sesion.certificaciones = sessionSelected.certificaciones
      this.sesion.estatusActivo = sessionSelected.estatusActivo
      this.sesion.ponenteNivelA = sessionSelected.ponenteNivelA
      this.sesion.email_interno = sessionSelected.email_interno
    },
    showModalDelete(item){
      this.sesion.id= item.id
      this.sesion.nombre= item.nombre
      this.sesion.apellidos= item.apellidos
      this.modalDeleteSesion = true
    },
  },
  mounted() {
  this.getComponentes()
  },


}
</script>
<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
  background-color: #B2007B !important;
  border-color: #B2007B !important;
  color: white !important;
}

.page-link {
  border-color: #B2007B !important;
  color: #B2007B !important;
}

.alert-fixed {
  position: fixed;
  bottom: 2%;
  left: 30%;
  z-index: 9999;
  border-radius: 5px;
}
</style>