import api from '@/services/api';

export default{
    subirNivel(formData){
        let url          = `/api/componentes/subirNivel`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    bajarNivel(formData){
        let url          = `/api/componentes/bajarNivel`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    getComponentes()
    {
        let url          = `/api/componentes/all`;
        let response   = api({
            url: url,
            method: 'GET',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            }
        });
        return response;
    },
    guardarFechador(formData){
        let url          = `/api/componentes/guardarFechador`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    guardarPonencia(formData){
        let url          = `/api/ponentes/storePonente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    actualizarPonente(formData){
        let url          = `/api/ponentes/updatePonente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
    deletePonente(formData){
        let url          = `/api/ponentes/deletePonente`;
        let response     = api({
            url: url,
            method: 'POST',
            headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
            },
            data: formData
        });
        return response;
    },
}